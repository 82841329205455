import React, { useState, useEffect } from "react";
import './Payment.css';
import { calculateHash, } from '../../Api';


const PaymentPage = ({

  firstname,
  lastname,
  email,
  paymentTitle,
  amount,
  sendUserId,
  reciveUserID,
  setPaymentSuccess,
  setOrderID,
  phone,
  date,
  address,
  city,
  country,
  checked,
  courseName
}) => {
  const [Pay, setPay] = useState({});


  useEffect(() => {
    const fetchHash = async () => {
      try {
        const hash = await calculateHash(amount);
        setPay(hash);
      } catch (error) {
        console.error(error);
      }
    };

    fetchHash();
  }, [amount]);



  var payment = {
    sandbox: true,
    //merchant_id: "1227407",//dilshan
    merchant_id: "1228505",//rainbow
    //merchant_id: "227983",//rainbow - live
    return_url: 'http://sample.com/return',
    cancel_url: 'http://sample.com/cancel',
    notify_url: 'https://rainbowbackend.ceylonxcorporation.com/notify',
    order_id: Pay.orderId,
    items: paymentTitle,
    amount: parseFloat(Pay.amount), // or Number(Pay.amount)

    currency: "LKR",
    hash: Pay.hash,
    first_name: firstname,
    last_name: firstname,
    email: email,
    phone: phone,
    address: address,
    city: city,
    country: country,
    custom_1: sendUserId,
    custom_2: courseName,
  };
  console.log("paymentdetails", payment);

  const pay = (e) => {
    e.preventDefault(); // prevent default form submission
    if (checked && amount > 0) {

      window.payhere.startPayment(payment);
      console.log("paymentdetails", payment);
    } else {
      alert("Please check the checkbox and enter a valid payment amount");
    }
  }


  window.payhere.onCompleted = function onCompleted(order_id) {
    console.log("Payment completed. OrderID:", order_id);
    setPaymentSuccess(true);
    setOrderID(order_id);

  };

  return (

    <button className='button' variant="primary" onClick={pay}>Payment</button>
  );
};

export default PaymentPage;