import { Routes, Route } from "react-router-dom";
//import React, { useState, useEffect } from 'react';
import './App.css';
import "./index.css";
import Home from './pages/Home/Home';
import Confirm from './pages/Confirm/Confirm';
import Payment from './pages/Payment/Payment';
import Back from './pages/Back/Back';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import Receipt from './pages/Receipt/Receipt'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="confirm" element={<Confirm />} />
        <Route path="payment" element={<Payment />} />
        <Route path="back" element={<Back />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="receipt" element={<Receipt />} />
      </Routes>
    </>
  );
}

export default App;
