import React, { useState, useEffect } from 'react';
import './Payment.css';
import Form from 'react-bootstrap/Form';
import PaymentPage from "./PaymentPage";
import moment from 'moment';
import { getOnlineStudentData, getLastPaymentDate, getPaymentAmount } from '../../Api'; // Import API functions

function Payment() {
    const [onlineStudentData, setOnlineStudentData] = useState({});
    const [payment, setPayment] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [orderID, setOrderID] = useState(null);
    const [lastPaymentDate, setLastPaymentDate] = useState('');
    const studentID = localStorage.getItem('studentID');
    const currentDate = new Date();
    const currentDateTime = currentDate.toLocaleString();

    const handlePaymentChange = (e) => {
        const value = e.target.value;
        if (/^\d+(\.\d+)?$/.test(value)) {
            setPayment(value);
        } else {
            alert("Invalid payment amount");
        }
    };

    useEffect(() => {
        const fetchOnlineStudentData = async () => {
            if (studentID) {
                try {
                    const data = await getOnlineStudentData(studentID);
                    setOnlineStudentData(data);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        const fetchLastPaymentDate = async () => {
            if (studentID && onlineStudentData.description_course) {
                try {
                    const date = await getLastPaymentDate(studentID, onlineStudentData.description_course);
                    setLastPaymentDate(moment(date).format('YYYY-MM-DD HH:mm:ss'));
                } catch (error) {
                    console.error(error);
                }
            }
        };

        const fetchPaymentAmount = async () => {
            if (studentID) {
                try {
                    const amount = await getPaymentAmount(studentID);
                    setPayment(amount);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchOnlineStudentData();
        fetchLastPaymentDate();
        fetchPaymentAmount();
    }, [studentID, onlineStudentData.description_course]);

    useEffect(() => {
        if (paymentSuccess && orderID) {
            window.location.href = `/back?orderID=${orderID}&studentID=${studentID}`;
        }
    }, [paymentSuccess, orderID, studentID]);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    // const handlePayment = (e) => {
    //     e.preventDefault(); // prevent default form submission
    //     if (isChecked && payment > 0) {
    //         // navigate to the next page
    //         window.location.href = "/back";
    //     } else {
    //         alert("Please check the checkbox and enter a valid payment amount");
    //     }
    // };

    return (
        <div>
            <section className='payment'>
                <div className='payment_form'>
                    <div className='payment_img_form'>
                        <img src="/assets/logo.jpg" alt="" />
                    </div>
                    <div className='payment_text_form'>
                        <h1>Confirm Your Details</h1> <br />
                        <Form.Label htmlFor="inputPassword5">Membership ID</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Membership_ID"
                            id="Membership_ID"
                            value={onlineStudentData.code_stundent || ''}
                            placeholder="Membership ID"
                            required=""
                            autoComplete="off"
                            readOnly
                        /> <br />
                        <Form.Label htmlFor="inputPassword5">Student Level</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Student_Level"
                            id="Student_Level"
                            value={onlineStudentData.description_course || ''}
                            placeholder="Membership ID"
                            required=""
                            autoComplete="off"
                            readOnly
                        /> <br />

                        <Form.Label htmlFor="inputPassword5">Last Payment</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Last_Payment"
                            id="Last_Payment"
                            placeholder="Last Payment"
                            value={lastPaymentDate}
                            required=""
                            autoComplete="off"
                            readOnly
                        />
                        <Form.Label htmlFor="inputPassword5" style={{ marginTop: '20px' }}>Monthly Payment (You can change the amount if needed)</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Student_Payment"
                            min="0"
                            id="Student_Payment"
                            value={payment}
                            placeholder="Student Payment"
                            required=""
                            autoComplete="off"
                            onChange={handlePaymentChange}
                            style={{ marginBottom: '-20px' }}
                        />

                        <br />
                        <p>* LKR 100 will be added to the amount as processing Fee</p>

                        <Form>
                            {['checkbox'].map((type) => (
                                <div key={`default-${type}`} className="mb-3">
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        label={` By selecting 'Proceed to Payment', you are confirming that you have read and agree to Rainbow Swimming Academy's`}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            ))}
                        </Form>
                        <PaymentPage
                            firstname={onlineStudentData.fullname_student}
                            lastname={onlineStudentData.gender_student}
                            email={onlineStudentData.email_student}
                            paymentTitle={onlineStudentData.description_course}
                            amount={payment}
                            sendUserId={onlineStudentData.code_stundent} // who send money
                            reciveUserID={"Rainbow Swimming"} // who will recive
                            setPaymentSuccess={setPaymentSuccess}
                            setOrderID={setOrderID}
                            phone={onlineStudentData.mobile_student}
                            date={currentDateTime}
                            address={onlineStudentData.address_student}
                            city={onlineStudentData.town_student}
                            country="Sri Lanka"
                            checked={isChecked}
                            courseName={onlineStudentData.description_course}
                        />

                        <div className='privacy'>
                            <p>
                                <a href="privacy" target='_blank'>Privacy Policy</a> | {""}
                                <a href="terms" target='_blank'>Terms Conditions</a> | {""}
                                <a href="return" target='_blank'>Return Policy</a>
                            </p>

                            <p>**Please Contact Us on 0112 680 882 for support</p>
                        </div>
                    </div>
                </div>
                <p className='text-center'>Powered By <a href="https://ceylonxcorp.com/">CeylonX Corporation</a></p>
            </section>
        </div>
    );
}

export default Payment;
