import React, { useState, useEffect } from 'react';
import { getOnlineStudentData, getStudentData, updateStudentEmail } from '../../Api';
import './Confirm.css';
import Form from 'react-bootstrap/Form';

function Confirm() {
    const [onlineStudentData, setOnlineStudentData] = useState({});
    const [email, setEmail] = useState('');
    //  const [isEmailEditable, setIsEmailEditable] = useState(false);
    const [originalEmail, setOriginalEmail] = useState(''); // Store the original email

    useEffect(() => {
        const fetchOnlineStudentData = async () => {
            const studentID = localStorage.getItem('studentID');
            if (studentID) {
                try {
                    const data = await getOnlineStudentData(studentID);
                    setOnlineStudentData(data);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchOnlineStudentData();
    }, []);

    useEffect(() => {
        const fetchStudentData = async () => {
            const studentID = localStorage.getItem('studentID');
            if (studentID) {
                try {
                    const data = await getStudentData(studentID);
                    // Convert "null" string to an empty string for consistency
                    const emailValue = data.email_student === "null" ? '' : data.email_student;
                    setEmail(emailValue);
                    setOriginalEmail(emailValue); // Set the original email

                    // setIsEmailEditable(!emailValue); // Allow editing if email is empty
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchStudentData();
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async () => {
        const studentID = localStorage.getItem('studentID');
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            alert("Please add a valid email to proceed.");
            return;
        }


        // if (isEmailEditable) {
        if (email !== originalEmail) {
            try {
                await updateStudentEmail(studentID, email);
                alert("Email updated successfully.");
            } catch (error) {
                alert("Failed to update email. Please try again.");
                console.error(error);
                return;
            }
        }
        window.location.href = '/payment';
    };

    return (
        <div>
            <section className='confirm'>
                <div className='confirm_form'>
                    <div className='confirm_img_form'>
                        <img src="/assets/logo.jpg" alt="Logo" />
                    </div>
                    <div className='confirm_text_form'>
                        <h1>Confirm Your Details</h1> <br />
                        <Form.Label>Membership ID</Form.Label>
                        <input
                            className='input'
                            type="text"
                            value={onlineStudentData.code_stundent || ''}
                            readOnly
                        /> <br />

                        <Form.Label>Student Level</Form.Label>
                        <input
                            className='input'
                            type="text"
                            value={onlineStudentData.description_course || ''}
                            readOnly
                        /> <br />

                        <Form.Label>Member</Form.Label>
                        <input
                            className='input'
                            type="text"
                            value={onlineStudentData.fullname_student || ''}
                            readOnly
                        /> <br />

                        <Form.Label>Birthday</Form.Label>
                        <input
                            className='input'
                            type="date"
                            value={onlineStudentData.dob_student ? new Date(onlineStudentData.dob_student).toISOString().split('T')[0] : ''}
                            readOnly
                        /> <br />

                        <Form.Label>Mobile</Form.Label>
                        <input
                            className='input'
                            type="text"
                            value={onlineStudentData.mobile_student || ''}
                            readOnly
                        /> <br />

                        <Form.Label>Email</Form.Label>
                        <input
                            className='input'
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            // readOnly={!isEmailEditable}
                            placeholder="Enter your email"
                            required
                        /> <br />

                        <button className='button' onClick={handleSubmit}>Confirm</button>
                        <div className='privacy'>
                            <p>
                                <a href="privacy" target='_blank'>Privacy Policy</a> |
                                <a href="terms" target='_blank'>Terms & Conditions</a> |
                                <a href="return" target='_blank'>Return Policy</a>
                            </p>
                            <p>**Please Contact Us on 0112 680 882 for support</p>
                        </div>
                    </div>
                </div>
                <p className='text-center'>Powered By <a href="https://ceylonxcorp.com/">CeylonX Corporation</a></p>
            </section>
        </div>
    );
}

export default Confirm;
