import ReactToPrint from "react-to-print";
import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Receipt.css';
import { getPaymentDetails, getOnlineStudentData, getStudentData, sendEmail } from '../../Api';

function PaymentReceipt() {
  const [paymentData, setPaymentData] = useState({});
  const [studentData, setStudentData] = useState({});
  const [studentEmail, setStudentEmail] = useState('');
  const componentRef = useRef();
  const dateTime = new Date();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderID = queryParams.get('orderID');
  const studentID = queryParams.get('studentID');

  useEffect(() => {
    if (orderID) {
      getPaymentDetails(orderID)
        .then(data => setPaymentData(data))
        .catch(error => console.error(error));
    }
  }, [orderID]);

  useEffect(() => {
    if (studentID) {
      getOnlineStudentData(studentID)
        .then(data => setStudentData(data))
        .catch(error => console.error(error));

      getStudentData(studentID)
        .then(data => {
          setStudentEmail(data.email_student);  // Set student's actual email
        })
        .catch(error => console.error(error));
    }
  }, [studentID]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const htmlContent = document.querySelector('.container').outerHTML;

    try {
      const emailResponse = await sendEmail(studentEmail, htmlContent);
      alert(emailResponse.success ? "Email sent successfully" : "Failed to send email");
    } catch (error) {
      console.error(error);
    }

    // try {
    //   const adminResponse = await sendAdminEmail(paymentData, studentID, orderID);
    //   console.log(adminResponse.success ? "Email sent for admin successfully" : "Failed to send email to admin");
    // } catch (error) {
    //   console.error(error);
    // }
  };

  return (
    <div className="receipt">
      <div className="top">
        <p> Receipt for the payment : {paymentData.payment_id} </p>
        <div className="buttons">
          <ReactToPrint
            trigger={() => <button className='button' variant="primary">GET A PRINTED COPY</button>}
            content={() => componentRef.current}
            documentTitle="Payment Receipt"
            pageStyle="print"
            onAfterPrint={() => { console.log('Receipt printed successfully') }}
          />
          <button className='button' onClick={handleSubmit} variant="primary">GET AN EMAIL</button>
        </div>
      </div>

      <div ref={componentRef}>
        <div className="container" style={{ width: '80%' }}>
          <header className="header" style={{ textAlign: 'center', marginBottom: '20px', fontSize: '14px' }} >
            <h1>Rainbow Swimming Academy (Pvt) Ltd</h1>
            <h2>No.29, Matland Place, Colombo 07</h2>
            <h2>Tel:{studentData.mobile_student}</h2>
          </header>
          <hr />
          <div className="receipt-details" style={{ fontSize: '20px', fontWeight: '500' }}>
            <ul>
              <li>{dateTime.toLocaleDateString()} {dateTime.toLocaleTimeString()}</li>
              <li>OrderID: {orderID}</li>
              <li>Payment Method: {paymentData.method}</li>
              <li>Customer: {paymentData.card_holder_name}</li>
              <li>Contact Number: 94772396002</li>
            </ul>
          </div>
          <hr />
          <table className="table" style={{ width: '100%', marginBottom: '20px' }}>
            <thead className="table-header">
              <tr>
                <th>Order ID</th>
                <th>Payment ID</th>
                <th>Student ID</th>
                <th>Date and Time</th>
                <th>Card Holder</th>
                <th>Payment for</th>
                <th>Amount (LKR)</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr>
                <td>{orderID}</td>
                <td>{paymentData.payment_id}</td>
                <td>{paymentData.student_payment_details}</td>
                <td>{dateTime.toLocaleDateString()} {dateTime.toLocaleTimeString()}</td>
                <td>{paymentData.card_holder_name}</td>
                <td>{studentData.description_course}</td>
                <td>{paymentData.payhere_amount}</td>
              </tr>
            </tbody>
            <tfoot className="table-footer">
              <tr>
                <td>Total Amount</td>
                <td>{paymentData.payhere_amount}</td>
              </tr>
            </tfoot>
          </table>
          <hr />
          <div className="policies" style={{ fontWeight: '500' }}>
            <h2>Policies and Procedures</h2>
            <p>All payments are non-refundable</p>
            <h2>Make-up Classes:</h2>
            <p>Provided that the monthly fees have been paid in full, missed classes can be rescheduled based on availability.</p>
            <p>Payment of Monthly Fee:</p>
            <ul style={{ listStyleType: 'disc' }}>
              <li>Fees must be paid by the 15th of each invoiced month.</li>
              <li>Students whose fees are not paid on or before the end of the billing month will be automatically suspended in the system.</li>
              <li>Suspended students will need to reactivate their profile by paying all arrears and penalties as follows: </li>
            </ul>
            <ol style={{ listStyleType: 'decimal', fontSize: '11px' }}>
              <li>If the student has not attended classes and has not paid the invoiced fees, the student will restart by paying a rejoining fee plus the monthly fee going forward.</li>
              <li>If the student has attended classes and has not paid the invoiced fees, the student needs to pay all arrears plus fees for the current month to restart classes.</li>
            </ol>
            <p>For any clarification, please contact the pool reception at 112680882.</p>
            <p>Please note that the rates provided above are subject to change at management's discretion.</p>
          </div>
          <hr />
          <footer className="footer" style={{ textAlign: 'center' }}>
            <p>***This is a computer-generated document. No signature required***</p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default PaymentReceipt;
