import axios from "axios";

//const API_BASE_URL = "https://rainbowbackend.ceylonxcorporation.com";
const API_BASE_URL = "http://localhost:8081";


// Login user API call
export const loginUser = async (membershipID, birthDay) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/login`, {
            id: membershipID,
            dob: birthDay
        }, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        throw new Error("Error occurred while logging in");
    }
};

// Get  student data API call
export const getStudentData = async (studentID) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get`, { params: { id: studentID } });
        return response.data[0];
    } catch (error) {
        throw new Error("Error occurred while fetching online student data");
    }
};

// Get online student data API call
export const getOnlineStudentData = async (studentID) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/getOnline`, { params: { id: studentID } });
        return response.data[0];
    } catch (error) {
        throw new Error("Error occurred while fetching online student data");
    }
};

// New function to update student's email
export const updateStudentEmail = async (studentID, email) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/updateEmail`, { id: studentID, email });
        return response.data;
    } catch (error) {
        throw new Error("Error occurred while updating email");
    }
};

// Get last payment date API call
export const getLastPaymentDate = async (studentID, level) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/getLastPaymentDate`, { params: { id: studentID, level } });
        return response.data.lastPaymentDate;
    } catch (error) {
        throw new Error("Error occurred while fetching last payment date");
    }
};

// Get payment amount API call
export const getPaymentAmount = async (studentID) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/getPaymentAmount`, { params: { id: studentID } });
        return response.data.paymentAmount;
    } catch (error) {
        throw new Error("Error occurred while fetching payment amount");
    }
};

// Calculate hash API call
export const calculateHash = async (amount) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/calculateHash`, null, { params: { amount } });
        return response.data;
    } catch (error) {
        throw new Error("Error occurred while calculating hash");
    }
};

// Get payment details API call
export const getPaymentDetails = async (orderID) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/getPaymentDetails`, { params: { id: orderID } });
        return response.data[0];
    } catch (error) {
        throw new Error("Error occurred while fetching payment details");
    }
};

// Send email API call
export const sendEmail = async (to, htmlContent) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/email`, { to, html: htmlContent }, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        throw new Error("Error occurred while sending email");
    }
};

// Send admin email API call
export const sendAdminEmail = async (paymentData, studentID, orderID) => {
    try {
        const { payhere_amount, card_holder_name, payment_id, method } = paymentData;
        const response = await axios.post(`${API_BASE_URL}/adminEmail`, {
            amount: payhere_amount,
            from: card_holder_name,
            pay_for: payment_id,
            method,
            studentID,
            orderID
        }, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        throw new Error("Error occurred while sending admin email");
    }
};


// Check payment status API call
export const checkPaymentStatus = async (orderId, studentId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/checkPaymentSuccess`, {
            orderid: orderId,
            studentid: studentId
        }, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        throw new Error("Error occurred while checking payment status");
    }
};
